import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "upload-file"
    }}>{`Upload File`}</h2>
    <p>{`To upload a file you need to send a POST request containing the base64 encoded file `}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://api.anymessage.cloud/upload/new/:extension"
      }}>{`https://api.anymessage.cloud/upload/new/:extension`}</a></p>
    <p>{`Note: replace :extension with your file extension. If you want to upload a mp3 file use mp3 as :extension`}</p>
    <h3 {...{
      "id": "post-body"
    }}>{`POST Body`}</h3>
    <p>{`Yor request body should be JSON encoded`}</p>
    <h3 {...{
      "id": "post-parameters"
    }}>{`POST parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from anymesage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`anyDir001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from anymesage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hz$o932`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of your file. Should be unique to address the file later`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`myAudioFile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base64 encoded content of your file`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iVBORw0KGgoAAAANSUhEUgAAA ... HwAAAABJRU5ErkJggg==`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h3 {...{
      "id": "request-examples"
    }}>{`Request examples`}</h3>
    <p>{`The following examples fulfill this scenario: `}</p>
    <ol>
      <li parentName="ol">{`You want to upload a mp3 file`}</li>
      <li parentName="ol">{`You want to reference the file late by the name MyFirstFile`}</li>
      <li parentName="ol">{`AnyMessage provided you the following credentials username = anyDir001 and password = hz7r§j`}</li>
      <li parentName="ol">{`The file you want to upload is located at /var/tmp/sample.mp3`}</li>
    </ol>
    <h4 {...{
      "id": "shell"
    }}>{`Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/bin/bash

base64 = $(base64 -w 0 /var/tmp/sample.mp3)

IFS='' read -r -d '' CONTENT <<EOF
{
    "username":"anyDir001", 
    "password": hz7r§j, 
    "name":"MyFirstFile", 
    "content": "$base64"
}
EOF

curl -L "https://api.anymessage.cloud/upload/new/mp3" -XPOST -d "$CONTENT"

`}</code></pre>
    <p>{`Please note`}</p>
    <ul>
      <li parentName="ul">{`The middle part of the base64 content string is left out `}</li>
    </ul>
    <h4 {...{
      "id": "php"
    }}>{`PHP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php

$url = 'https://api.anymessage.cloud/upload/new/mp3';

$username = 'anyDir001'; 
$password = 'hz7r§j'; 
$name = 'MyFirstFile'; 
$fileLocation = '/var/tmp/sample.mp3'; 



$data = file_get_contents($fileLocation);
$content = base64_encode($data);

$postParameter = array(
    'username'=>$username,
    'password'=>$password,
    'name'=>$name,
    'content'=>$content
);
$postParameterJson = json_encode($postParameter);

$requestHeader = array(
    "Accept: application/json",
    "Content-Type: application/json"
);


$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_HTTPHEADER, $requestHeader); 
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, $postParameterJson);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);


$response = curl_exec($ch);

// Response evaluation 
if($response === false){
    var_dump(curl_error($ch));
}else{
    var_dump($response);
}
?>
`}</code></pre>
    <h3 {...{
      "id": "request-responses"
    }}>{`Request responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`You Receive a HTTP status code of 200 and a json formatted message containing a success message.`}</p>
    <p>{`A sample success response looks like this.`}</p>
    <pre><code parentName="pre" {...{}}>{`> {"success":true}
`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <pre><code parentName="pre" {...{}}>{`| HTTP status Code | Http response                           | description                                               |
| ---------------- | --------------------------------------- | --------------------------------------------------------- |
| 400              | {"message":"Not all params specified!"} | The request does not contain all mandatory POST parameter |
| 400              | {"message":"name parameter to long"}    | The provided name is to long. Maximal length is 50        |
| 400              | {"message":"name already exist"}        | For your user is already a upload with this name present  |
| 400              | {"message":"name is no valid filename"} | name parameter needs to be a valid filename               |
| 401              | {"message":"Invalid credentials"}       | The provided credentials are not valid                    |
| 403              | {"message":"Action forbidden"}          | Your account is not authorized to use this endpoint       |
| 500              | {"message":"Internal Error"}            | An internal error occurred                                |
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      